@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DINOT';
  src: url('./assets/DINOT/DINOT-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'DINOT', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


