.source-link{
  color: #9E9C9C;
  text-decoration: none;
}
.histogram-container, .swarm-container{
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14pt;
  }
}
.main-histogram-container{
  .histogram-container{
    height: 100%;
  }
  .compare-button{
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #231f20;
  }
  .compare-button:hover{
    color: #a29f9f;
    border: 1px solid #a29f9f;
  }
}
.swarm-container{
  height: 100px;
  cursor: pointer;
  .swarm-circle{
    cursor: pointer;
  }
}

.main-container-loading{
  /* LOADING DATA */
  width:100%;
  .loading-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14pt;

  }    
  .loading-spinner {
    margin-left:20px;
  }
}
.header-message-center{
  width: calc(100% - 485px - 40px - 40px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .exporting-spinner{
    width: 100%;
    text-align: center;
    display: contents;
    .export-spinner{
      margin-left: 5px;
    }
  }
  .off{
    display: none;
  }
}
.main-container{
  /* MAP */
  .map-hist-container{
    width:36.25%;
    height:100%;
    .leaflet-container{
      .leaflet-control-custom{
        background-color: #FFFFFF;
        border: 1px solid #231f20;
        padding-bottom: 10px;
        .map-radio-control{
          padding: 10px;
          padding-bottom: 0px;
          font-size: 10px;
          line-height: 10px;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 4px;
          .map-radio-control-label {
            font-weight: bold;
          }
        }
        
        .map-radio-control label {
          display: block; /* Makes each label block-level, causing a new line for each */
          margin: 4px 0; /* Adds some space between each label */
          cursor: pointer; /* Makes it clear that the labels are interactive */
          .map-radio-control-text{
            display: inline-flex;
            position: relative;
            bottom: 2px;
            display:inline-flex;
          }
        }
        
        .map-radio-control input[type="radio"] {
          margin-right: 5px; /* Adds some space between the radio button and the label text */
        }
      }
    }
  }
  .featured-view-container{
    border-left: 1px solid #282c34;
    width:calc(100% - 36.25%);
    height:100%;
  }
  .featured-view-container-scatter{
    width:100%;
    height:100%;
    display:flex;
  }
  /* HISTOGRAM SELECTIONS */
  .hist-container-dynamic{
    position: absolute;
    /*background-color: aliceblue;*/
    pointer-events:none;
    padding: 10px;
    .hist-bar-container{
      height:100%;
      display: flex;
      justify-content: space-between;
      .hist-bar{
        margin-top: auto; 
      }
    }
  }
  .hist-label-rect.popup-selected{
      z-index:1 !important;
  }
  .hist-label-rect.popup-hovered{
      z-index:2 !important;
  }
  .overlay-text {
    font-size: 12px;
    font-family: Arial, sans-serif;
    /* Other styles */
  }

  /* HISTOGRAM STATIC BARS */
  .hist-container{
    padding: 10px;
    /*min-height: 200px;*/
    .hist-bar-container{
      height:100%;
      display: flex;
      justify-content: space-between;
      .hist-bar{
        margin-top: auto; 
        background-color: #282c34;
      }
      .hovered{
        background-color: #e11384 !important;
        z-index:4000 !important;
        width:3px !important;
      }
      .selected{
        background-color: #00aed9 !important;
        z-index:4000 !important;
        width:3px !important;
      }
    }
  }
  .module-parent {
    display: flex;
    flex-direction: row;
    position:relative;
    //height: 100vh; /* Full height of the viewport */
  }
  
  .module-child-1-meta {
    width: 100%; /*33.33%;  1/3 of the parent width */
    //background-color: lightblue; /* For visual distinction */
    flex-grow: 1;
    align-content: center;
    padding-bottom:10px;
    .module-meta-stat-label{
      font-size: 12px;
      display: flex;
      flex-direction: row;
      .module-meta-stat{
        width: 100%; /*20%;*/
        flex-grow: 1;
        text-align: start; /*end;*/
        font-weight: bold;
        font-size: 24px;
        padding-right: 10px;
      }
      .module-meta-label{
        width: 80%;
        flex-grow: 2;
      }
    }
  }
  
  .module-child-2-chart {
    width: 66.67%; /* 2/3 of the parent width */
    //background-color: lightcoral; /* For visual distinction */
    flex-grow: 2;
  }
}
.swarm-chart-dynamic, .swarm-chart-static{
  padding-left:10px;
  padding-right:10px;
}
.swarm-container-dynamic{
  pointer-events:none;
}

.bivariate-legend {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 33%;//100px;
  left: calc(66% - 25px);
  position: relative;
  top: 25px;
}
.bivariate-legend-export{
  position: absolute;
  bottom: 25px;
  right:25px;
  z-index: 1000;
}
.bivariate-legend-x {
  position: absolute;
  width: 33%;
  padding-bottom: calc(33% - 2px); /* to give this a width of 33% of the parent */
  text-align: center;
  left: calc(66% - 25px);
  z-index:3;
  height:0px;
  margin-top: 33%;
  top: 23px;
  /*background-color:aqua;*/
  .bivariate-legend-x-text {
    padding-top: 20px;
    text-align: left;
  }
  .bivariate-legend-x-arrow {
    background-color: black;
    width: 66px;
    height: 2px;
    position: absolute;
    top: 17px;
    left: calc(50% - 2px);
    .triangle-left {
      width: 0;
      height: 0;
      border-top: 5px solid transparent; /* Adjust size */
      border-right: 8.66px solid black; /* Adjust size */
      border-bottom: 5px solid transparent; /* Adjust color and size */
      position: absolute;
      left: -4px;
      top: -4px;
    }
    .triangle-right {
      width: 0;
      height: 0;
      border-top: 5px solid transparent; /* Adjust size */
      border-left: 8.66px solid black; /* Adjust size */
      border-bottom: 5px solid transparent; /* Adjust color and size */
      position: absolute;
      left: 66px;
      top: -4px;
    }
  }
}
.bivariate-legend-meta {
  position: absolute;
  top: 25px;
  left: 25px;;
  width: 33%;
  text-align: center;
  /*background-color:hsl(126, 84%, 48%);*/
  text-align: left;
  .bivariate-legend-meta-title {
    font-weight: bold;
  }
  .bivariate-legend-meta-text {
    font-size: 10pt;
    line-height: 14pt;
  }
}
.bivariate-legend-y {
  position: absolute;
  top: 25px;
  left: calc(44% - 23px);
  width: calc(22% - 2px);
  text-align: center;
  /*background-color:#e11384;*/
  text-align: left;
  height: 0px;
  padding-bottom: calc(33% - 2px); /* to give this a height of 33% of the parent */
  /*rotate: 270deg;*/
  .bivariate-legend-y-text {
    text-align: right;
    padding-top: calc(50% + 25px);
    padding-right: 10px;
  }
  .bivariate-legend-y-arrow {
    background-color: black;
    width: 2px;
    height: 66px;
    position: absolute;
    top: 5px;
    left: calc(100% - 20px);
    .triangle-up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent; /* Adjust size */
      border-right: 5px solid transparent; /* Adjust size */
      border-bottom: 8.66px solid black; /* Adjust color and size */
      position: absolute;
      left: -4px;
      top: -4px;
    }
    .triangle-down {
      width: 0;
      height: 0;
      border-left: 5px solid transparent; /* Adjust size */
      border-right: 5px solid transparent; /* Adjust size */
      border-top: 8.66px solid black; /* Adjust color and size */
      position: absolute;
      left: -4px;
      top: 66px;
    }
  }
}


.legend-row {
  display: flex;
  width: 100%;
}

.legend-color {
  flex-grow: 1;
  padding-top: 33%;
  position: relative;
}

.legend-color::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
}

/* 3RD PARTY */
.rw-widget-picker{
  min-height:20px !important;
}

/* PIE */
.container .slice {
  transition-duration: 0.3s;
  transition-property: filter, opacity;
  filter: saturate(100%);
  opacity: 1;
  cursor: pointer;
}

.container.hasHighlight .slice {
  filter: saturate(50%);
  opacity: 0.2;
}

.container.hasHighlight .slice:hover {
  filter: saturate(100%);
  opacity: 1;
}